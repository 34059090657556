import { useEffect } from 'react'
import { useSetEoState } from '~/src/hooks/useEoState'
import { BACK_BUTTON_STATE, BackButtonState } from '~/src/stores/back-button'

const useBackButton = (state: BackButtonState): void => {
  const setBackButtonState = useSetEoState(BACK_BUTTON_STATE)

  useEffect(() => {
    setBackButtonState(state)
  }, [ setBackButtonState ])
}

export default useBackButton
